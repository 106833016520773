import { callApi } from '../utils/callApi'
import { ApiRoutes } from '../utils/apiRoutes'
import { useDispatch } from 'react-redux'
import { showGlobalPopup } from '../store/globalPopupSlice'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { IOfferItem } from '../types/offerTypes'

export interface IPdfWithItemsData {
  items: IOfferItem[]
  hide_price_items: boolean
  hide_total_price: boolean
}

export const useOfferDocs = () => {
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()
  const [loadPdfLoading, setLoadPdfLoading] = useState(false)
  const [purchaseContractLoading, setPurchaseContractLoading] = useState(false)
  const [consignmentContractLoading, setConsignmentContractLoading] = useState(false)

  const loadPdf = async (
    agreement: 'decision' | 'purchase' | 'consignment' | 'offer',
    expiration_date?: string,
    external_note?: string
  ) => {
    setLoadPdfLoading(true)
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.print}?lang=${i18n.language}&agreement=${agreement}${expiration_date ? `&expiration_date=${expiration_date}` : ''}${external_note ? `&external_note=${external_note}` : ''}`,
        responseType: 'arraybuffer',
      })

      if (resp) {
        const iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i)
        const userAgent = navigator.userAgent.toLowerCase()
        const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('android')

        var file = new Blob([resp.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        if (iOS && isSafari) {
          link.download = agreement[0] + agreement.slice(1) + ' form'
          link.rel = 'noopener noreferrer'
        }
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setLoadPdfLoading(false)
    }
  }

  const loadPdfWithItems = async (data: IPdfWithItemsData) => {
    setLoadPdfLoading(true)
    try {
      const resp = await callApi({
        method: 'POST',
        url: `${ApiRoutes.printItems}?lang=${i18n.language}`,
        responseType: 'blob',
        data: data,
      })

      if (resp) {
        var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i)
        const userAgent = navigator.userAgent.toLowerCase()
        const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('android')
        var file = new Blob([resp.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        if (iOS && isSafari) {
          link.download = 'Offer form'
          link.rel = 'noopener noreferrer'
        }
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setLoadPdfLoading(false)
    }
  }

  const loadPurchaseContract = async () => {
    setPurchaseContractLoading(true)
    const agreement = 'purchase'
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.printContract}?lang=${i18n.language}&agreement=${agreement}`,
        responseType: 'arraybuffer',
      })

      if (resp) {
        var file = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.download = t('Contract of Sale')
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setPurchaseContractLoading(false)
    }
  }

  const loadConsignmentContract = async () => {
    setConsignmentContractLoading(true)
    const agreement = 'consignment'
    try {
      const resp = await callApi({
        method: 'GET',
        url: `${ApiRoutes.printContract}?lang=${i18n.language}&agreement=${agreement}`,
        responseType: 'arraybuffer',
      })

      if (resp) {
        var file = new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const url = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.download = t('Consignment Agreement')
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.log(error, 'error data')
      dispatch(showGlobalPopup({ type: 'error', title: 'Error', text: error }))
    } finally {
      setConsignmentContractLoading(false)
    }
  }

  return {
    loadPdfLoading,
    purchaseContractLoading,
    consignmentContractLoading,
    loadPdf,
    loadPdfWithItems,
    loadPurchaseContract,
    loadConsignmentContract,
  }
}

import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { IPaymentInfo, IStore } from '../../../types/storeTypes'
import { DefaultButton } from '../../defaultButton'
import { v4 as uuidv4 } from 'uuid'
import { LoadPaymentInfoViaMobileQrCode } from '../../modals/QRCodeModals/loadPaymentInfoViaMobileQrCode'
import { useDispatch, useSelector } from 'react-redux'
import { IPlaidData } from '../../../types/additionalTypes'
import { hideSymbols } from '../../../utils/hideSymbols'
import { DefaultTooltip } from '../../defaultTooltip'
import { removePaymentInfoFromMobile } from '../../../store/paymentInfoFromMobileSlice'
import { routingNumberValidator } from '../../../utils/routingNumberValidator'
import parse from 'html-react-parser'
import { usePayments } from '../../../hooks/usePayments'

interface Props {
  paymentData: IPaymentInfo['payment_data'] | null
  setPaymentData: (value: IPaymentInfo['payment_data']) => void
}

export const BankInfoBlock: React.FC<Props> = ({ paymentData, setPaymentData }) => {
  const { t, i18n } = useTranslation()
  const [showQRCode, setShowQRCode] = useState(false)
  const [routingNumberWarn, setRoutingNumberWarn] = useState(false)
  const [token, setToken] = useState<string>(uuidv4())
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null)
  const paymentInfoFromMobile = useSelector((store: IStore) => store.paymentInfoFromMobile)
  const dispatch = useDispatch()
  const { getPlaidLinkToken } = usePayments()

  useEffect(() => {
    if (!showQRCode) setToken(uuidv4())
  }, [showQRCode])

  useEffect(() => {
    if (paymentInfoFromMobile.uploaded && paymentInfoFromMobile.data) {
      const data = paymentInfoFromMobile.data as IPlaidData
      if (data) {
        setPaymentData({
          ...paymentData,
          account_number: data.accountNumber,
          routing_number: data.routingNumber,
          plaid_account_id: data.accountId,
          plaid_public_token: paymentInfoFromMobile.token,
          payee_name: data.clientName || '',
          is_plaid: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfoFromMobile])

  const onEditField = (name: string, value: any) => {
    if (name === 'show_promo_amount') {
      setPaymentData({ ...paymentData, show_promo_amount: value, promo_amount: value ? 200 : 0 })
    } else setPaymentData({ ...paymentData, [name]: value })
  }

  const onPlaidDelete = () => {
    dispatch(removePaymentInfoFromMobile())
    setPaymentData({
      ...paymentData,
      account_number: '',
      routing_number: '',
      plaid_account_id: undefined,
      plaid_public_token: undefined,
      is_plaid: false,
    })
  }

  const disableNameInput = useMemo(() => {
    const plaidAcc = paymentInfoFromMobile.data ? paymentInfoFromMobile.data : null
    return (!!paymentData?.is_plaid && !!plaidAcc && !!plaidAcc.clientName) || (!!paymentData?.is_plaid && !plaidAcc)
  }, [paymentData?.is_plaid, paymentInfoFromMobile.data])

  const onRoutingInputFinish = () => {
    if (paymentData?.routing_number) {
      const checkValue = routingNumberValidator(paymentData?.routing_number)
      if (!checkValue) setRoutingNumberWarn(true)
      else setRoutingNumberWarn(false)
    } else {
      setRoutingNumberWarn(false)
    }
  }

  useEffect(() => {
    let timer: any = null
    if (timer) clearTimeout(timer)
    if (paymentData?.routing_number) {
      timer = setTimeout(() => {
        onRoutingInputFinish()
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData?.routing_number])

  const openQRcodeModal = async () => {
    if (!plaidLinkToken) {
      const token = await getPlaidLinkToken()
      if (token) setPlaidLinkToken(token)
    }
    setShowQRCode(true)
  }

  return (
    <div className='form_block'>
      <div className='form_block_title'>{t('Bank Information')}</div>
      <div className='w-full flex items-center'>
        <label htmlFor='input-paymentData-payee_name' className='w-1/3 flex form_label'>
          {t('Payee Name')}
        </label>
        <input
          type='text'
          name='payee_name'
          id='input-paymentData-payee_name'
          value={paymentData?.payee_name}
          onChange={(e) => onEditField('payee_name', e.target.value)}
          className='form-input w-2/3'
          autoComplete='false'
          disabled={disableNameInput}
        />
      </div>

      <div className='w-full flex items-center'>
        <label htmlFor='input-paymentData-account_number' className='w-1/3 flex form_label'>
          {t('Account No.')}
          <span className='text-red-500 pl-1'>*</span>
        </label>
        {!!paymentData?.plaid_account_id || paymentData?.is_plaid ? (
          <div className='form-input w-2/3 opacity-70 pointer-events-none'>
            {hideSymbols(paymentData?.account_number || '', 4)}
          </div>
        ) : (
          <input
            type='text'
            name='account_number'
            id='input-paymentData-account_number'
            value={paymentData?.account_number || ''}
            onChange={(e) => onEditField('account_number', e.target.value)}
            className='fs-mask form-input w-2/3'
            autoComplete='false'
            disabled={!!paymentData?.plaid_account_id || paymentData?.is_plaid}
          />
        )}
      </div>

      <div className='w-full flex items-center'>
        <label htmlFor='input-paymentData-routing_number' className='w-1/3 flex form_label'>
          {t('Routing No.')}
          <span className='text-red-500 pl-1'>*</span>
        </label>
        {!!paymentData?.plaid_account_id || paymentData?.is_plaid ? (
          <div className='form-input w-2/3 opacity-70 pointer-events-none'>
            {hideSymbols(paymentData?.routing_number || '', 4)}
          </div>
        ) : (
          <div className='w-2/3 relative'>
            <input
              type='text'
              name='routing_number'
              id='input-paymentData-routing_number'
              value={paymentData?.routing_number || ''}
              onChange={(e) => {
                const { value } = e.target
                const regex = /^\d+$/
                if (value && !value[value.length - 1].match(regex)) return
                onEditField('routing_number', value)
                setRoutingNumberWarn(false)
              }}
              className={`fs-mask form-input w-full ${routingNumberWarn ? '!border-yellow-500' : ''}`}
              autoComplete='false'
              disabled={!!paymentData?.plaid_account_id || paymentData?.is_plaid}
              onBlur={onRoutingInputFinish}
            />
            {routingNumberWarn ? (
              <div className='absolute top-2 right-2'>
                <DefaultTooltip
                  id='routing_number-tooltip'
                  buttonStyle='border-2 border-yellow-500 bg-white text-yellow-500 !h-5 !w-5 !text-sm'
                  place='top-start'
                  buttonIcon='!'
                >
                  <ul className='list-disc pl-3'>
                    <li>{t('Length must be 9 digits.')}</li>
                    <li>{parse(t('The first two digits can only be:<br/> 00 - 12, 21 - 32, 61 - 72, or 80.'))}</li>
                  </ul>
                </DefaultTooltip>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div className='w-full flex items-center'>
        <label htmlFor='input-paymentData-add_promotion_bonus' className='w-1/3 flex form_label pr-2'>
          {t('Add promotion bonus')}
        </label>
        <input
          type='checkbox'
          name='add_promotion_bonus'
          id='input-paymentData-add_promotion_bonus'
          checked={paymentData?.show_promo_amount}
          onChange={() => onEditField('show_promo_amount', !paymentData?.show_promo_amount)}
          className={`form-input w-[16px] h-[16px] accent-green-700`}
          autoComplete='false'
        />
      </div>

      {paymentData?.show_promo_amount && (
        <div className='w-full flex items-center'>
          <label htmlFor='input-paymentData-promo_amount' className='w-1/3 flex form_label pr-2'>
            {t('Promotion Amount')}
          </label>
          <input
            type='text'
            name='promo_amount'
            id='input-paymentData-promo_amount'
            value={paymentData?.promo_amount || ''}
            onChange={(e) => {
              var regex = /^\d+$/
              if (e.target.value.match(regex) || e.target.value === '') {
                onEditField('promo_amount', +e.target.value)
              }
            }}
            className='form-input w-2/3'
            autoComplete='false'
          />
        </div>
      )}

      <div className='w-full flex items-center justify-end'>
        {!!paymentData?.plaid_account_id || paymentData?.is_plaid ? (
          <span className='flex w-2/3 [&>div]:w-full'>
            <DefaultButton
              title={t('Disconnect bank account')}
              onClick={onPlaidDelete}
              classname='h-[38px] w-full red_button'
            />
          </span>
        ) : (
          <div className='flex w-2/3 [&>div]:w-[calc(100%-24px)]'>
            <DefaultButton
              title={t('Connect bank account')}
              onClick={openQRcodeModal}
              classname='h-[38px] w-full blue_button'
            />
            <DefaultTooltip id='plaid-help' clickable>
              <Trans
                defaults={`Connect client's bank account`}
                components={[
                  <a
                    href='https://www.bill.com/'
                    target='_blank'
                    className='text-blue-400 hover:text-blue-500 underline'
                    rel='noreferrer'
                  >
                    bill.com
                  </a>,
                ]}
              />
            </DefaultTooltip>
          </div>
        )}
      </div>

      <LoadPaymentInfoViaMobileQrCode
        visible={showQRCode}
        setVisible={setShowQRCode}
        qrCodeValue={`${window.location.origin}/${i18n.language}/add-payment-data/?upload_token=${token}&link_token=${plaidLinkToken || ''}`}
        token={token}
      />
    </div>
  )
}
